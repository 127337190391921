<template>
    <v-dialog
        persistent
        v-model="paySalesCommissionDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="600px"
        min-width="500px"
        max-width="700px"
    >
        <v-card>
            <v-card-title class="success white--text">{{ $t('message.markSelectedCommissionsAsPaid') }}</v-card-title>
            <v-card-text>
                <v-form lazy-validation ref="paySalesCommissionForm">
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.paymentDate') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-menu
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                max-width="290px"
                                min-width="290px"
                                ref="dateMenu"
                                v-model="dateMenu"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        :label="date ? '' : $t('message.required')"
                                        :rules="[rules.date]"
                                        :value="computedDateFormatted"
                                        append-icon="event"
                                        background-color="white"
                                        class="force-text-left"
                                        clearable
                                        hide-details="auto"
                                        hint="DD/MM/YY format"
                                        persistent-hint
                                        readonly
                                        solo
                                        v-on="on"
                                        @click:clear="date = null"
                                        @click:append="dateMenu = true"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    first-day-of-week="1"
                                    locale-first-day-of-year="4"
                                    show-week
                                    v-model="date"
                                    @input="dateMenu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn color="default" small @click="paySalesCommissionDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                    :loading="loading.paid"
                    color="info"
                    small
                    @click="paySelectedSalesCommission()"
                >{{ $t('message.proceed') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {formatDate} from "Helpers/helpers";
// import router from "@/router";
import {api} from "Api";

export default {
    name: "PaySelectedSalesCommission",
    props: ['invoices','dialog','dialog-closed','commissions-paid'],
    data() {
        return {
            date: new Date().toISOString().substr(0, 10),
            dateMenu: false,
            dialogs: {
                error:  false,
                error_message: ""
            },
            loading: {
                paid: false
            },
            paid_dialog: false,
            rules: {
                date: v => !!v || this.$t('message.required')
            },
        }
    },
    computed: {
        computedDateFormatted () {
            return formatDate(this.date)
        },
        paySalesCommissionDialog: {
            get() {
                return this.paid_dialog;
            },
            set(value){
                this.paid_dialog = value
                if(value == false) {
                    this.$emit('dialog-closed')
                }
            }
        },
    },
    methods: {
        paySelectedSalesCommission() {
            if(this.$refs.paySalesCommissionForm.validate()) {
                this.loading.paid = true
                api
                    .post('/commissions/mark-paid', {
                        commissions: this.invoices,
                        date: this.date
                    })
                    .then((response) => {
                        this.$toast.success(this.$t('message.successes.markAsPaid'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                        this.loading.paid = false
                        this.$emit('commissions-paid')
                        this.paySalesCommissionDialog = false
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.notMarkedAsPaid'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.paid = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.notMarkedAsPaid'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        }
    },
    watch: {
        dialog(value) {
            if(value == false){
                // this.amount = 0,
                this.date = new Date().toISOString().substr(0, 10)
                this.$refs.paySalesCommissionForm.resetValidation()
            }
            this.paid_dialog = value
        }
    },

}
</script>

<style scoped>

</style>